import React, {FC} from 'react';
import {Link} from "react-router-dom";
import {Box, Container, Grid, Typography} from "@mui/material";
import {BOARD_TEMPLATES} from "./boardTemplates";
import {BoardTemplate} from "./models";
import logo from "./rt_penguin_somb_lines.svg";
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';


export const TemplateSelection = () => {
    return (
        <Container maxWidth={'sm'} sx={{mt: 5}}>
            <Box sx={{height: 200, textAlign: 'center', my: 3}}>
                <img src={logo} alt="logo" height={"100%"}/>
            </Box>
            <Box sx={{textAlign: 'center', m: 4}}>
                <Typography>Select a Starting Template</Typography>
            </Box>
            <Grid container spacing={2}>
                {BOARD_TEMPLATES.map(t => <BoardTemplateOption key={t.id} template={t}/>)}
            </Grid>
        </Container>
    );
}


export const BoardTemplateOption: FC<{ template: BoardTemplate }> = ({template}) => {
    return (
        <Grid item xs={6}>

            <Link to={`/builder/${template.id}`}>
                <Box sx={{
                    // background: 'tomato',
                    // height: 50,
                    textAlign: 'center',
                    p: 4,
                    borderRadius: 2,
                    border: '4px solid #AAA',
                    color: '#666'
                }}>
                    {template.id === "meeting"
                        ? <EventIcon sx={{fontSize: 80}}/>
                        : <GroupsIcon sx={{fontSize: 80}}/>
                    }


                    <Typography> {template.title}</Typography>
                </Box>
            </Link>
        </Grid>
    );
}

