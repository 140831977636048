import React, {FC} from 'react';
import {Container, Grid} from "@mui/material";

export const MainBodyLayout: FC<{
    left: React.ReactNode,
    right: React.ReactNode,
}> = ({left, right}) => {
    return (
        <Container maxWidth={false} sx={{pb: 9}}>
            <Grid container spacing={3}>
                <Grid item sm={4}>
                    {left}
                </Grid>
                <Grid item sm={8}>
                    {right}
                </Grid>
            </Grid>
        </Container>
    );
}
