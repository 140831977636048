import React, {FC, useCallback, useContext, useMemo} from 'react';
import {Box, Typography} from "@mui/material";
import {orderBy} from "lodash";
import {PointableCardDisabled} from "../comp/PointableCard";
import {NewPointCard} from "../comp/NewPointCard";
import {PointCard} from "../../models";
import {PrincipalContext} from "../../PrincipalContext";


export const CardSectionBuilder: FC<{
    cards: PointCard[],
    pointsAvailable: number,
    addCard: (cardBody: string, authorId: string) => void
    deleteCard: (id: string) => void
}> = ({cards, pointsAvailable, addCard, deleteCard}) => {
    const orderedCards = useMemo(() => {
        return orderBy(cards, c => c.createdAt, 'asc')
    }, [cards])

    const {principal} = useContext(PrincipalContext)
    const principalAddCard = useCallback((body: string) => addCard(body, principal!.id), [addCard, principal])


    return (
        <Box>
            {/*<CardSectionHeaderBuilding pointsAvailable={pointsAvailable}/>*/}
            <Box sx={{overflow: 'none'}}>
                {orderedCards
                    .map((item) => <PointableCardDisabled
                        key={item.id}
                        item={item}
                        deleteCard={deleteCard}
                    />)
                }
                <NewPointCard addCard={principalAddCard}/>
            </Box>
        </Box>
    )
}

export const CardSectionHeaderBuilding: FC<{
    pointsAvailable: number
}> = ({pointsAvailable}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <Box sx={{mr: 1, fontSize: 12, p: 1}}>
                <Typography sx={{fontSize: 12, fontWeight: 700, color: '#666'}}>
                    Each participant will have {pointsAvailable} points to assign
                </Typography>
            </Box>
        </Box>
    );
}
