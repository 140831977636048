import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";

// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {collection, doc, getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {BoardMeta, Participant, PointCard} from "./models";
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions} from "@firebase/firestore";
import moment from "moment";
import {PrincipalProvider} from "./PrincipalProvider";
import {isNil, omitBy} from "lodash";
import {blueGrey, cyan, orange, teal} from "@mui/material/colors";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCJI0f2aKpbfG1e8arf3LOTKLyvlxBFzEI",
    authDomain: "roomtemp-83215.firebaseapp.com",
    projectId: "roomtemp-83215",
    storageBucket: "roomtemp-83215.appspot.com",
    messagingSenderId: "219801888931",
    appId: "1:219801888931:web:53678e654bb11860fd7716",
    measurementId: "G-TEY6EPT474"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
export const fireAuth = getAuth(app)

const scoreboardMetaConverter: FirestoreDataConverter<BoardMeta> = {
    toFirestore(meta: BoardMeta): DocumentData {
        return omitBy({
            ...meta,
            createdAt: meta.createdAt?.toISOString(),
            scoringModeActivationTime: meta.scoringModeActivationTime?.toISOString(),
            scoringFreezeTime: meta.scoringFreezeTime?.toISOString(),
        }, isNil);
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): BoardMeta {
        const data = snapshot.data(options)! as BoardMeta;
        return {
            ...data,
            createdAt: moment(data.createdAt),
            scoringModeActivationTime: !!data.scoringModeActivationTime
                ? moment(data.scoringModeActivationTime)
                : undefined,
            scoringFreezeTime: !!data.scoringFreezeTime ? moment(data.scoringFreezeTime) : undefined,
        }
    }
};

const scoreboardCardConverter: FirestoreDataConverter<PointCard> = {
    toFirestore(meta: PointCard): DocumentData {
        return {
            ...meta,
            createdAt: meta.createdAt.toISOString(),
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): PointCard {
        const data = snapshot.data(options)! as PointCard;
        return {
            ...data,
            createdAt: moment(data.createdAt),
        };
    }
};

const participantConverter: FirestoreDataConverter<Participant> = {
    toFirestore(meta: Participant): DocumentData {
        return {
            ...meta,
            createdAt: meta.createdAt.toISOString(),
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<DocumentData>,
        options?: SnapshotOptions
    ): Participant {
        const data = snapshot.data(options)! as Participant;
        return {
            ...data,
            createdAt: moment(data.createdAt),
        };
    }
};

export const scoreboardsCollectionRef = collection(db, "scoreboards")
    .withConverter<BoardMeta>(scoreboardMetaConverter)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: blueGrey,
        secondary: {
            main: '#87eae2',
            light: cyan[100],
            dark: cyan[400],
        },
        warning: {main: orange[500]},
    },
    components: {

        MuiButton: {
            styleOverrides: {
                containedSecondary: {
                    background: cyan[100],
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                groupedContainedSecondary: {
                    borderColor: cyan[200] + ' !important'
                }
            }

        },
        // MuiBadge: {
        //     styleOverrides: {
        //         colorSecondary: {
        //             background: teal['A100']
        //         }
        //     }
        // },
        // MuiCircularProgress: {
        //     styleOverrides: {
        //         colorPrimary: {
        //             // color: teal[400]
        //         }
        //     }
        // },
    },


});

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <PrincipalProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </PrincipalProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


export class ScoreboardDb {
    constructor(private boardId: string) {
    }

    boardRef = doc(scoreboardsCollectionRef, this.boardId)
    getParticipantsRef = () => collection(this.boardRef, "participants")
        .withConverter(participantConverter)
    getParticipantRef = (participantId: string) => doc(this.getParticipantsRef(), participantId)
        .withConverter(participantConverter)
    getCardsRef = () => collection(this.boardRef, "cards")
        .withConverter(scoreboardCardConverter)
    getCardRef = (cardId: string) => doc(this.getCardsRef(), cardId)
        .withConverter(scoreboardCardConverter)
}
