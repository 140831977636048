import React, {FC} from 'react';
import {Typography, useTheme} from "@mui/material";


export const PrincipalPointsAvailableStatus: FC<{
    pointsAvailable: number
}> = ({pointsAvailable}) => {
    const {palette} = useTheme()
    if(pointsAvailable < 1) {
        return (
            <Typography sx={{fontSize: 12, fontWeight: 700, color: palette.primary.main}}>
                All points have been assigned
            </Typography>
        )
    } else if (pointsAvailable === 1) {
        return (
            <Typography sx={{fontSize: 12, fontWeight: 700, color: palette.warning.main}}>
                1 point left to assign!
            </Typography>
        )
    } else {
        return (
            <Typography sx={{fontSize: 12, fontWeight: 700, color: palette.warning.main}}>
                {pointsAvailable} points left to assign!
            </Typography>
        )
    }
}

