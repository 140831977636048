import React, {createContext} from 'react';
import {CircularProgress, Container} from "@mui/material";
import {BoardManager} from "../models";
import {Route, Routes, useParams} from "react-router-dom";
import {PointBoardPhaseSwitch} from "./PointBoardPhaseSwitch";
import {AdminSectionPage} from "./sections/AdminSectionPage";
import {useBoardManager} from "./useBoardManager";

export function PointBoardRoutes() {

    const {boardId} = useParams<{ boardId: string }>();
    const bm = useBoardManager(boardId!)

    if (!bm.meta) {
        return (
            <Container sx={{textAlign: 'center', marginTop: '40vh'}}>
                <CircularProgress size={70}/>
            </Container>
        )
    } else {
        return (
            <BoardManagerContext.Provider value={bm}>
                <Routes>
                    <Route path="/admin" element={<AdminSectionPage/>}/>
                    <Route path="/" element={<PointBoardPhaseSwitch/>}/>
                </Routes>
            </BoardManagerContext.Provider>
        );
    }
}

export const BoardManagerContext = createContext<BoardManager>(null as any)
