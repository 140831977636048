import React, {FC, useContext, useMemo} from 'react';
import {Container} from "@mui/material";
import {BoardManagerContext} from "../../index";
import {UserAvatar} from "../../comp/header/UserAvatar";
import {BoardHeader} from "../../comp/header/BoardHeader";
import {MainBodyLayout} from "../../comp/layout/MainBodyLayout";
import {InfoSectionScoring} from "./InfoSectionScoring";
import {CardSectionScoring} from "./CardSectionScoring";


export const ScoringPhase: FC = () => {
    const bm = useContext(BoardManagerContext)
    const meta = useMemo(() => bm.meta!, [bm])
    return (
        <Container maxWidth={'xl'}>
            <BoardHeader title={meta.title} userMenuSection={<UserAvatar/>}/>
            <MainBodyLayout
                left={<InfoSectionScoring/>}
                right={<CardSectionScoring/>}
            />
        </Container>
    )
}
