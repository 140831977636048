import React, {FC, useCallback, useContext, useMemo, useState} from 'react';
import {Box, Dialog} from "@mui/material";
import {orderBy} from "lodash";
import {PointableCard} from "../../comp/PointableCard";
import {BoardManagerContext} from "../../index";
import {AddParticipantDialogGate} from "../../comp/AddParticipantDialogGate";
import {NewPointCard} from "../../comp/NewPointCard";
import {PrincipalPointsAvailableStatus} from "../../comp/PrincipalPointsAvailableStatus";
import {PrincipalContext} from "../../../PrincipalContext";
import {FreezeScoringHeaderSection} from "../../comp/FreezeScoringHeaderSection";


export const CardSectionScoring: FC = () => {

    const {addCard, cards, cardPoints, principal: principalMan, isFrozen, meta} = useContext(BoardManagerContext)
    const {principal, showLoginDialog, openLoginDialog, closeLoginDialog} = useContext(PrincipalContext)
    const principalAddCard = useCallback((body: string) => addCard(body, principal!.id), [addCard, principal])

    const orderedCards = useMemo(() => {
        if (isFrozen && principalMan.pointsAvailable === 0) {
            return orderBy(cards, c => cardPoints[c.id]?.length || 0, 'desc')
        } else {
            return orderBy(cards, c => c.createdAt, 'asc')
        }
    }, [isFrozen, principalMan.pointsAvailable, cards, cardPoints])


    return (
        <Box>
            <CardSectionHeaderScoring
                pointsAvailable={
                    !!principalMan.participant ? principalMan.pointsAvailable : meta!.maxPointsPerParticipant
                }
                hidePrincipalPointsStatus={!principalMan.participant && isFrozen}
            />
            <Box sx={{overflow: 'none'}}>
                {orderedCards
                    .map((item) => <PointableCard
                        key={item.id}
                        item={item}
                        addPointOverride={
                            (!principalMan.participant && !isFrozen) ? openLoginDialog : undefined
                        }
                    />)
                }
                {!isFrozen && <NewPointCard
                    addCard={principalAddCard}
                    submitOverride={!principalMan.participant ? openLoginDialog : undefined}
                />}
            </Box>
            <Dialog
                fullWidth
                maxWidth={'xs'}
                onClose={closeLoginDialog}
                open={!principalMan.participant && showLoginDialog}
            >
                <AddParticipantDialogGate/>
            </Dialog>
        </Box>
    )
}

export const CardSectionHeaderScoring: FC<{
    pointsAvailable: number,
    hidePrincipalPointsStatus: boolean
}> = ({pointsAvailable, hidePrincipalPointsStatus}) => {

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1}}>
            <FreezeScoringHeaderSection/>
            {!hidePrincipalPointsStatus && <Box sx={{fontSize: 12}}>
                <PrincipalPointsAvailableStatus pointsAvailable={pointsAvailable}/>
            </Box>}
        </Box>
    );
}
