import React from 'react';
import {Route, Routes} from "react-router-dom";
import {TemplateSelection} from "./TemplateSelection";
import {PointBoardRoutes} from "./board";
import {BoardBuilderPreSave} from "./board/builder";

export const RootRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<TemplateSelection/>}/>
            <Route path="/b/:boardId/*" element={<PointBoardRoutes/>}/>
            <Route path="/builder/:templateId" element={<BoardBuilderPreSave/>}/>
        </Routes>
    );
}
