import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Dialog,
    FormControlLabel, Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {BOARD_TEMPLATES_MAP} from "../../boardTemplates";
import {MaxPointsSelector} from "./MaxPointsSelector";
import {useCreateScoreboard} from "./useCreateScoreboard";
import moment from "moment";
import {BoardTemplate, PointCard} from "../../models";
import {BoardHeader} from "../comp/header/BoardHeader";
import {UserAvatar} from "../comp/header/UserAvatar";
import {MainBodyLayout} from "../comp/layout/MainBodyLayout";
import {CardSectionBuilder} from "./CardSectionBuilder";
import {faker} from "@faker-js/faker";
import {PrincipalContext} from "../../PrincipalContext";
import {BuilderLoginGate} from "../comp/BuilderLoginGate";

export const BoardBuilderPreSave = () => {
    const {templateId} = useParams<{ templateId: string }>();
    const template = BOARD_TEMPLATES_MAP[templateId!]
    return (
        <BoardBuilderForm template={template}/>
    );
}

export const BoardBuilderForm: FC<{ template: BoardTemplate }> = ({template}) => {

    const {principal} = useContext(PrincipalContext)
    const [savingBoard, setSavingBoard] = useState(false)
    const [boardReadyToSave, setBoardReadyToSave] = useState(false)
    const showLoginPrompt = useMemo(() => boardReadyToSave && !principal, [principal, boardReadyToSave])
    const [title, setTitle] = useState<string>(template.title)
    const [scoringInstructions, setScoringInstructions] = useState<string>(template.scoringInstructions)
    const [voteCount, setVoteCount] = useState<number>(template.maxPointsPerParticipant);
    const [cards, setCards] = useState<PointCard[]>(template.cards);
    const addCard = useCallback((body: string) => {
        setCards(c => [...c, {
            id: faker.datatype.uuid(),
            description: body,
            createdAt: moment(),
            authorId: 'INIT'
        }])
    }, [setCards])
    const deleteCard = useCallback((cardId: string) => {
        setCards(crds => crds.filter(c => c.id !== cardId))
    }, [setCards])

    const {createNewBoard, error, loading} = useCreateScoreboard()

    useEffect(() => {
        if (boardReadyToSave && !!principal) {
            const titleTrim = title?.trim()
            const scoringInstructionsTrim = scoringInstructions?.trim()
            if (titleTrim && scoringInstructionsTrim && principal) {
                try {
                    setSavingBoard(true)
                    createNewBoard(
                        {
                            title: titleTrim,
                            scoringInstructions: scoringInstructionsTrim,
                            maxPointsPerParticipant: voteCount,
                            createdAt: moment(),
                            createdBy: principal.id,
                            managers: {[principal.id]: {id: principal.id, owner: true}}
                        },
                        cards,
                        principal
                    )

                } finally {
                    setSavingBoard(false)
                }
            }
        }
    }, [boardReadyToSave, principal, savingBoard, title, scoringInstructions, createNewBoard, voteCount, cards])

    const onSubmit = useCallback(() => {
        if (title?.trim() && scoringInstructions?.trim() && !savingBoard) {
            setBoardReadyToSave(true)
        }
    }, [title, scoringInstructions, setBoardReadyToSave, savingBoard])


    return (


        <Container maxWidth={'xl'}>
            <BoardHeader
                title={title}
                userMenuSection={<UserAvatar/>}
            />
            <MainBodyLayout
                left={
                    <Box>
                        <Box>
                            <TextField
                                fullWidth
                                autoFocus
                                value={title}
                                sx={{mt: 2}}
                                label={"Title"}
                                onChange={e => setTitle(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                value={scoringInstructions}
                                sx={{mt: 2}}
                                label={"Scoring Instructions"}
                                multiline
                                rows={4}
                                onChange={e => setScoringInstructions(e.target.value)}
                            />

                            <MaxPointsSelector value={voteCount} update={setVoteCount}/>
                            <FormControlLabel
                                sx={{my: 2, mx: 0, flexGrow: 1, width: '100%'}}
                                labelPlacement={'start'}
                                control={<Switch defaultChecked/>}
                                label={
                                    <Typography sx={{width: '100%'}}>
                                        All participants can add cards for scoring
                                    </Typography>
                                }
                            />
                        </Box>
                        <Box>
                            <Stack direction={'row'} sx={{mt: 3}} spacing={1}>
                                <LoadingButton
                                    fullWidth
                                    loading={loading}
                                    variant={'contained'}
                                    onClick={onSubmit}
                                    size={'large'}
                                    color={'primary'}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    fullWidth
                                    variant={'contained'}
                                    onClick={onSubmit}
                                    size={'large'}
                                    color={'secondary'}
                                >
                                    Share
                                </Button>
                            </Stack>
                            {error && <Typography sx={{p: 5}} color={'error'}>{error}</Typography>}
                        </Box>


                        <Dialog onClose={() => setBoardReadyToSave(false)} open={showLoginPrompt}>
                            <BuilderLoginGate/>
                        </Dialog>

                    </Box>
                }
                right={
                    <CardSectionBuilder
                        cards={cards}
                        addCard={addCard}
                        pointsAvailable={voteCount}
                        deleteCard={deleteCard}/>
                }
            />

        </Container>
    );
}
