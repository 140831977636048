import React, {FC} from 'react';
import logo from '../../../rt_penguin.svg';
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import logo2 from "../../../rt_penguin_somb_lines.svg";


export const BoardHeader: FC<{
    title: string,
    userMenuSection: React.ReactNode
}> = ({title, userMenuSection}) => {
    return (
        <header>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'relative',
                    alignItems: 'center'
                }}>
                    <Box sx={{height: 60, px: 3, py:4}}>
                        <Link to={'/'}>
                            {/*<img src={logo} alt="logo" height={"100%"}/>*/}
                            <img src={logo2} alt="logo" height={"100%"}/>
                        </Link>
                    </Box>
                    <Typography sx={{height: 60, pt: 3, flexGrow: 1}} variant={'h4'}>
                        {title}
                    </Typography>
                    {userMenuSection}
                </Box>
            </Box>
        </header>
    );
}
