import React, {FC, useCallback, useState} from 'react';
import {Button, Card, CardActions, CardContent, TextField} from "@mui/material";

export const NewPointCard: FC<{
    addCard: (cardBody: string) => void,
    submitOverride?: () => void,
}> = ({addCard, submitOverride}) => {
    const [cardBody, setCardBody] = useState<string>()
    const onSubmit = useCallback(() => {
        if (submitOverride) {
            submitOverride()
        } else {
            const trimmed = cardBody?.trim()
            if (trimmed) {
                addCard(trimmed)
                setCardBody(undefined)
            }
        }
    }, [submitOverride, cardBody, addCard])
    return (
        <Card sx={{mx: 1, my: 2}} elevation={3}>
            <CardContent sx={{mb: 0, p: 1, pb: 0}}>
                <TextField
                    fullWidth
                    color={'primary'}
                    label="Add Card"
                    multiline
                    value={cardBody || ""}
                    onChange={e => setCardBody(e.target.value)}
                    // variant="standard"
                    placeholder={"Add an option for participants to score..."}
                    rows={3}
                    // defaultValue={faker.lorem.sentences(4)}
                />
            </CardContent>
            <CardActions sx={{justifyContent: 'flex-end'}} disableSpacing>
                <Button onClick={onSubmit} size="small" color={'primary'}>Submit</Button>
            </CardActions>
        </Card>
    );
}
