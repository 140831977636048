import {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {setDoc} from "firebase/firestore";
import {ScoreboardDb} from "../../index";
import moment, {Moment} from "moment";
import {BoardAdmin, PointCard} from "../../models";
import {Principal} from "../../PrincipalContext";
import {nanoid} from 'nanoid'

export interface CreateNewBoardData {
    title: string
    scoringInstructions: string
    maxPointsPerParticipant: number
    createdAt: Moment,
    createdBy: string,
    managers: { [key: string]: BoardAdmin }
}

export const useCreateScoreboard = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const navigate = useNavigate();
    const createNewBoard = useCallback(async (data: CreateNewBoardData, cards: PointCard[], owner: Principal) => {
        try {
            setLoading(true)
            setError(undefined)
            const db = await createNewScoreboardDoc(data)

            // add the owner as the first participant
            await setDoc(db.getParticipantRef(owner.id), {
                id: owner.id,
                name: owner.name,
                createdAt: moment(),
                realLogin: owner.realLogin,
                points: []
            })

            // add all the cards
            cards.forEach((card) => {
                setDoc(db.getCardRef(card.id), {...card, authorId: owner.id})
            })

            navigate(`/b/${db.boardRef.id}`)
        } catch (e: any) {
            console.error("Error adding document: ", e);
            setLoading(false)
            setError(e?.message || "Error adding document")
        }
    }, [setLoading, setError, navigate])
    return {createNewBoard, error, loading}
}


const createNewScoreboardDoc = async (data: CreateNewBoardData): Promise<ScoreboardDb> => {
    let db: ScoreboardDb | undefined = undefined
    let count = 0
    while (!db && count < 10) {
        count++
        const newId = nanoid(10)
        const possibleDb = new ScoreboardDb(newId)
        try {
            await setDoc(possibleDb.boardRef, data)
            console.log("scoreboard created", newId);
            db = possibleDb
        } catch (e) {
            console.warn("Error creating new scoreboard", newId, e);
        }
    }
    return db!
}
