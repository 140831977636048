import React, {FC, useContext} from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import {PrincipalContext} from "../../PrincipalContext";

export const BuilderLoginGate: FC = () => {

    const {openLoginGoogle} = useContext(PrincipalContext)

    return (
        <Container maxWidth={'xs'}>
            <Box sx={{width: '100%'}}>
                <Typography variant={'h6'} sx={{mt: 3}}>Login to save</Typography>
            </Box>
            <Box>
                <Button
                    fullWidth
                    variant={'contained'}
                    sx={{mt: 2, mb: 3}}
                    onClick={openLoginGoogle}
                >
                    Login With Google
                </Button>
            </Box>
        </Container>
    );
}
