import React, {FC, PropsWithChildren} from 'react';
import {PrincipalContext, usePrincipalSvc} from "./PrincipalContext";

export const PrincipalProvider: FC<PropsWithChildren> = ({children}) => {
    const svc = usePrincipalSvc()
    return (
        <PrincipalContext.Provider value={svc}>
            {children}
        </PrincipalContext.Provider>
    );
}
