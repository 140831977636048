import {createContext, useCallback, useEffect, useMemo, useState} from "react";
import {fireAuth} from "./index";
import {GoogleAuthProvider, signInAnonymously, signInWithPopup, signOut, updateProfile} from "firebase/auth";

export interface Principal {
    id: string,
    name: string,
    realLogin: boolean
}

export interface PrincipalSvc {
    principal?: Principal
    showLoginDialog: boolean
    openLoginDialog: () => void,
    closeLoginDialog: () => void,
    loginAnon: (name: string) => void
    openLoginGoogle: () => void
    logout: () => void
}

export const usePrincipalSvc = (): PrincipalSvc => {
    const [showLoginDialog, setShowLoginDialog] = useState(false)
    const openLoginDialog = useCallback(() => setShowLoginDialog(true), [setShowLoginDialog])
    const closeLoginDialog = useCallback(() => setShowLoginDialog(false), [setShowLoginDialog])

    const [principal, setPrincipal] = useState<Principal | undefined>(fireAuth.currentUser ? {
        id: fireAuth.currentUser.uid,
        name: fireAuth.currentUser.displayName!,
        realLogin: !fireAuth.currentUser.isAnonymous
    } : undefined)

    useEffect(() => {
        console.log("principal changed: ", principal)
    }, [principal])

    useEffect(() => fireAuth.onAuthStateChanged(cu => {
            console.log("auth state changed:", cu)
            if (cu && cu.displayName) {
                console.log("setting principal:", cu.displayName)
                setPrincipal({
                    id: cu.uid,
                    name: cu.displayName!,
                    realLogin: !cu.isAnonymous
                })
                closeLoginDialog()
            }
        }),
        [setPrincipal]
    )


    const loginAnon = useCallback(async (name: string) => {
        const anonCred = await signInAnonymously(fireAuth)
        await updateProfile(anonCred.user, {displayName: name})
        setPrincipal({
            id: anonCred.user.uid,
            name: anonCred.user.displayName!,
            realLogin: false
        })
        closeLoginDialog()
    }, [])

    const googleProvider = useMemo(() => {
        const provider = new GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        return provider
    }, [])

    const openLoginGoogle = useCallback(async () => {
        try {
            const result = await signInWithPopup(fireAuth, googleProvider)
            // The signed-in user info.
            const user = result.user;
            console.log("login google: ", user)
            setPrincipal({
                id: user.uid,
                name: user.displayName!,
                realLogin: true
            })
            closeLoginDialog()

        } catch (e) {
            console.warn(e)
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.customData.email;
        }
    }, [googleProvider])


    const logout = useCallback(async () => {
        await signOut(fireAuth);
        setPrincipal(undefined)
    }, [])

    return {principal, loginAnon, openLoginGoogle, logout, showLoginDialog, openLoginDialog, closeLoginDialog}
}

export const PrincipalContext = createContext<PrincipalSvc>(null as any)


// const LS_PRINCIPAL_KEY = "principal"
// useEffect(() => {
//     const principalStr = localStorage.getItem(LS_PRINCIPAL_KEY)
//     if (principalStr) {
//         setPrincipal(JSON.parse(principalStr))
//     }
// }, [])
//
//
// const updatePrincipal = useCallback(
//     (name: string) => {
//         const principal = {
//             id: faker.datatype.uuid(),
//             name,
//         };
//         localStorage.setItem(LS_PRINCIPAL_KEY, JSON.stringify(principal))
//         setPrincipal(principal)
//     },
//     []
// )
