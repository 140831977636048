import React, {FC, useCallback, useContext} from 'react';
import {Box, Button} from "@mui/material";
import {faker} from "@faker-js/faker";
import {BoardManagerContext} from "../index";
import {PrincipalContext} from "../../PrincipalContext";

export const AdminSectionPage: FC = () => {
    const {addFakeParticipant, participants, addCard} = useContext(BoardManagerContext)
    const {principal} = useContext(PrincipalContext)
    const addFakeCard = useCallback(() => addCard(faker.lorem.sentences(3), principal?.id || 'ADMIN_FAKE'), [addCard, principal?.id])
    return (
        <Box>
            <Box sx={{my: 5}}>
                <Button onClick={addFakeParticipant}>Add fake Participant</Button>
                <Button onClick={addFakeCard}>Add fake Card</Button>
            </Box>
            <Box sx={{my: 5}}>
                {participants.map(p => (
                    <Box key={p.id}>
                        {p.name} - {p.points.length}
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
