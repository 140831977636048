import {keyBy} from "lodash";
import {BoardTemplate} from "./models";
import moment from "moment";
import {faker} from "@faker-js/faker";

export const BOARD_TEMPLATES: BoardTemplate[] = [
    {
        id: "meeting",
        title: "Sync Meeting Topics",
        scoringInstructions: "Add points to reflect your level of interest in discussing this topic at the" +
            " sync meeting.",
        maxPointsPerParticipant: 5,
        cards: [
            {
                id: faker.datatype.uuid(),
                description: "I'm feeling up to date.",
                createdAt: moment(),
                authorId: 'TEMPLATE'
            },
            {
                id: faker.datatype.uuid(),
                description: "[EXAMPLE] What are the latest updates on the OPTIMUS project? Are we on schedule for" +
                    " the upcoming deadline?",
                createdAt: moment().add(1, 'seconds'),
                authorId: 'TEMPLATE'
            },
            {
                id: faker.datatype.uuid(),
                description: "[EXAMPLE] Updates on the new job postings. How to take advantage of the referral " +
                    "bonus. $$",
                createdAt: moment().add(2, 'seconds'),
                authorId: 'TEMPLATE'
            },
        ]
    },
    {
        id: "poll",
        title: "Team Preference Poll",
        scoringInstructions: "Add points to reflect your level of preference for each item.",
        maxPointsPerParticipant: 5,
        cards: [
            {
                id: faker.datatype.uuid(),
                description: faker.lorem.sentences(2),
                createdAt: moment(),
                authorId: 'TEMPLATE'
            },
            {
                id: faker.datatype.uuid(),
                description: faker.lorem.sentences(2),
                createdAt: moment(),
                authorId: 'TEMPLATE'
            },
            {
                id: faker.datatype.uuid(),
                description: faker.lorem.sentences(2),
                createdAt: moment(),
                authorId: 'TEMPLATE'
            },
            {
                id: faker.datatype.uuid(),
                description: faker.lorem.sentences(2),
                createdAt: moment(),
                authorId: 'TEMPLATE'
            },
        ]
    },
]

export const BOARD_TEMPLATES_MAP = keyBy<BoardTemplate>(BOARD_TEMPLATES, t => t.id)
