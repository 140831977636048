import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {faker} from "@faker-js/faker";
import {countBy, forEach, fromPairs, maxBy, omit, range, sample, sumBy, values} from "lodash";
import {deleteDoc, deleteField, onSnapshot, query, setDoc, updateDoc, getDocs} from "firebase/firestore";
import {ScoreboardDb} from "../index";
import {BoardManager, BoardMeta, Participant, PointCard, PrincipalBoardManager, UpdateBoardMetaObj} from "../models";
import moment, {Moment} from "moment";
import {PrincipalContext} from "../PrincipalContext";

export const useBoardManager = (boardId: string): BoardManager => {
    const {principal} = useContext(PrincipalContext)

    const db = useMemo(() => new ScoreboardDb(boardId), [boardId])

    const [meta, setMeta] = useState<BoardMeta>()
    const [closeCheckCount, setCloseCheckCount] = useState(0)

    const secondsTillFrozen = useMemo(() => {
        if (!!meta?.scoringFreezeTime) {
            return meta.scoringFreezeTime.diff(moment(), 'seconds')
        } else {
            return undefined
        }
        // eslint-disable-next-line
    }, [meta?.scoringFreezeTime, closeCheckCount])

    const timeTillFrozenStr = useMemo(() => {
        return secondsTillFrozen ? moment.utc(secondsTillFrozen * 1000).format("m:ss") : undefined
    }, [secondsTillFrozen])

    const isFrozen = useMemo(() => {
        if (!!meta?.scoringFreezeTime) {
            return moment().isAfter(meta.scoringFreezeTime)
        } else {
            return false
        }
        // eslint-disable-next-line
    }, [meta?.scoringFreezeTime, closeCheckCount])

    // if close time is not null and not is closed, poll every second to see if now closed
    // we could probably simplify this, but for now I think this will work
    useEffect(() => {
        if (!!meta?.scoringFreezeTime && !isFrozen) {
            const interval = setInterval(() => {
                setCloseCheckCount(c => c + 1);
            }, 500);
            return () => clearInterval(interval);
        }
    }, [isFrozen, meta?.scoringFreezeTime]);

    useEffect(() => onSnapshot(db.boardRef, doc => {
        const bm: BoardMeta = doc.data() as any
        setMeta({...bm, id: doc.id});
    }), [db.boardRef])

    const updateMeta = useCallback((updateObj: UpdateBoardMetaObj) => {
        updateDoc(db.boardRef, updateObj as any)
    }, [db.boardRef])


    const setFreezeTime = useCallback((scoringFreezeTime: Moment) => {
        updateDoc(db.boardRef, {scoringFreezeTime: scoringFreezeTime.toISOString()} as any)
    }, [db.boardRef])

    const clearFreezeTime = useCallback(() => {
        updateDoc(db.boardRef, {scoringFreezeTime: deleteField()})
    }, [db.boardRef])


    const [cardMap, setCardMap] = useState<{ [key: string]: PointCard }>({})
    const cards = useMemo(() => values(cardMap), [cardMap])

    useEffect(() => onSnapshot(query(db.getCardsRef()), docs => {
        docs.forEach(d => {
            const p = d.data()
            setCardMap(pm => ({...pm, [p.id]: p}))
        })
        docs.docChanges().forEach(dc => {
            if (dc.type === 'removed') {
                setCardMap(pm => (omit(pm, dc.doc.id)))
            }
        })
    }), [db])


    const [participantsMap, setParticipantsMap] = useState<{ [key: string]: Participant }>()
    const participants = useMemo(() => values(participantsMap), [participantsMap])
    const principalParticipant = useMemo(
        () => (principal && participantsMap) ? participantsMap[principal?.id] : undefined,
        [principal, participantsMap]
    )

    useEffect(() => {
        if (!!principal && !!participantsMap && !principalParticipant) {
            console.warn("RESET_PRINCIPAL_POINTS")
            setDoc(db.getParticipantRef(principal.id), {
                id: principal.id,
                name: principal.name,
                realLogin: principal.realLogin,
                createdAt: moment(),
                points: []
            })
        }
    }, [principal, principalParticipant, participantsMap, db])

    useEffect(() => onSnapshot(query(db.getParticipantsRef()), docs => {
        docs.forEach(d => {
            const p = d.data()
            setParticipantsMap(pm => ({...pm, [p.id]: p}))
        })
        docs.docChanges().forEach(dc => {
            if (dc.type === 'removed') {
                setParticipantsMap(pm => (omit(pm, dc.doc.id)))
            }
        })
    }), [db])

    const cardPoints = useMemo(() => {
        const cardPointMap: { [key: string]: string[] } = fromPairs(cards.map(c => [c.id, []]));
        forEach(participantsMap, (p, participantId) =>
            p.points.forEach(cardId => {
                // checking if card exists because card can be deleted and points still exist in db
                if (cardPointMap[cardId]) {
                    cardPointMap[cardId] = [...cardPointMap[cardId], participantId]
                }
            })
        )
        return cardPointMap
    }, [cards, participantsMap])

    const totalPoints = useMemo(() => sumBy(participants, p => p.points.length), [participants])
    const maxPoints = useMemo(
        () => Math.max(maxBy(values(cardPoints), points => points.length)?.length || 0, 1),
        [cardPoints]
    )

    const resetParticipants = useCallback(async () => {
        const docs = await getDocs(db.getParticipantsRef())
        docs.forEach(doc => {
            deleteDoc(doc.ref)
        })
        clearFreezeTime()
    }, [])

    const addFakeParticipant = useCallback(() => {
        if (meta?.maxPointsPerParticipant) {
            const participant: Participant = {
                id: faker.datatype.uuid(),
                name: faker.name.firstName(),
                createdAt: moment(),
                realLogin: false,
                points: range(meta.maxPointsPerParticipant).map(() => sample(cards)!.id)
            }
            setDoc(db.getParticipantRef(participant.id), participant)
        }
    }, [cards, db, meta?.maxPointsPerParticipant])

    const addCard = useCallback((description: string, authorId: string) => {
        const card: PointCard = {
            id: faker.datatype.uuid(),
            description,
            createdAt: moment(),
            authorId
        }
        setDoc(db.getCardRef(card.id), card)
    }, [db])

    const deleteCard = useCallback(async (cardId: string) => {
        await deleteDoc(db.getCardRef(cardId))

    }, [db])

    const principalMan = usePrincipalBoardManager(db, meta, principalParticipant)

    return {
        db,
        principal: principalMan,
        meta,
        isFrozen,
        setFreezeTime,
        clearFreezeTime,
        secondsTillFrozen,
        timeTillFrozenStr,
        resetParticipants,
        updateMeta,
        cards,
        addCard,
        deleteCard,
        cardPoints,
        totalPoints,
        maxPoints,
        participants,
        addFakeParticipant,
    }
}


export const usePrincipalBoardManager = (
    db: ScoreboardDb,
    boardMeta?: BoardMeta,
    principal?: Participant
): PrincipalBoardManager => {

    const maxPointsPerParticipant = useMemo(() => boardMeta?.maxPointsPerParticipant || 0, [boardMeta])
    const isBoardManager = useMemo(
        () => !!principal && !!boardMeta && !!boardMeta.managers[principal.id],
        [principal?.id, boardMeta?.managers]
    )

    const points = useMemo<{ [key: string]: number }>(
        () => principal ? countBy(principal.points) : {},
        [principal]
    )

    const pointsAvailable = useMemo(
        () => !!principal ? maxPointsPerParticipant - principal.points.length : 0,
        [principal, maxPointsPerParticipant]
    )

    const addPoint = useCallback((cardId: string) => {
        if (principal && pointsAvailable > 0) {
            const principalRef = db.getParticipantRef(principal.id)
            const currentPoints = principal?.points || []
            updateDoc(principalRef, {points: [...currentPoints, cardId]})
        }
    }, [principal, pointsAvailable, db])

    const removePoint = useCallback((cardId: string) => {
        if (principal) {
            const index = principal.points.indexOf(cardId);
            if (index > -1) {
                const principalRef = db.getParticipantRef(principal.id)
                const update = [...principal.points]
                update.splice(index, 1)
                updateDoc(principalRef, {points: update})
            }
        }
    }, [principal, db])

    return {
        participant: principal,
        isBoardManager,
        points,
        pointsAvailable,
        addPoint,
        removePoint,
    }
}
