import React, {FC, ReactNode, useCallback, useContext, useMemo} from 'react';
import {Box, Button, ButtonGroup, Chip, Stack, Typography, useTheme} from "@mui/material";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
// import HeatIcon from '@mui/icons-material/LocalFireDepartment';
import HeatIcon from '@mui/icons-material/Brightness7';
import {BoardManagerContext} from "../index";
import moment from "moment";
import {cyan, grey} from "@mui/material/colors";

export const FreezeScoringHeaderSection: FC<{}> = ({}) => {
    const {
        setFreezeTime,
        timeTillFrozenStr,
        clearFreezeTime,
        isFrozen,
        secondsTillFrozen,
        principal
    } = useContext(BoardManagerContext)
    const crunchTime = useMemo(() => !!secondsTillFrozen && secondsTillFrozen < 30, [secondsTillFrozen])
    const setNowFreeze = useCallback(() => {
        setFreezeTime(moment())
    }, [setFreezeTime])
    const set2MinFreeze = useCallback(() => {
        setFreezeTime(moment().add(20, "seconds"))
    }, [setFreezeTime])
    const set4MinFreeze = useCallback(() => {
        setFreezeTime(moment().add(4, "minutes"))
    }, [setFreezeTime])

    const theme = useTheme()

    if (isFrozen || secondsTillFrozen == 0) {
        return <FreezeInfoLayout
            title={'Scoring is Frozen'}
            description={'Remaining points can be assigned, but can not be moved'}
            color={grey[700]}
            icon={<AcUnitOutlinedIcon sx={{mr: .5, color: theme.palette.secondary.main}} fontSize={'large'}/>}
            action={principal.isBoardManager && (
                <Button
                    onClick={clearFreezeTime}
                    variant={'outlined'}
                    color={'error'}
                    size={'small'}
                    sx={{fontSize: 8, ml: 1, p: .1}}
                >
                    Unfreeze
                </Button>
            )}
        />
    } else {
        return <FreezeInfoLayout
            title={'Scoring is Open'}
            description={'Points can be assigned and moved as desired'}
            color={grey[700]}
            icon={<HeatIcon sx={{mr: .5, color: theme.palette.warning.main}} fontSize={'large'}/>}
            timer={timeTillFrozenStr && <Chip
                size={'small'}
                color={crunchTime ? 'error' : 'default'}
                sx={{fontWeight: 700, fontSize: 14, ml: 1, height: 16}}
                icon={<TimerOutlinedIcon sx={{height: 14}}/>}
                label={timeTillFrozenStr}
            />}
            action={principal.isBoardManager && (
                !timeTillFrozenStr
                    ?
                    <ButtonGroup color={'secondary'} size={'small'} variant="contained"
                                 aria-label="outlined button group" sx={{ml: 1}}>
                        <Button sx={{fontSize: 8, px: 0.6, py: 0.1}} onClick={setNowFreeze}>
                            <AcUnitOutlinedIcon sx={{mr: .5, fontSize: 12}} fontSize={'small'}/>
                            Freeze
                        </Button>
                        <Button sx={{fontSize: 8, px: 0.6, py: 0.1}} onClick={set2MinFreeze}>
                            <TimerOutlinedIcon sx={{mr: .5, fontSize: 12}} fontSize={'small'}/>
                            2 min
                        </Button>
                        <Button sx={{fontSize: 8, px: 0.6, py: 0.1}} onClick={set4MinFreeze}>
                            <TimerOutlinedIcon sx={{mr: .5, fontSize: 12}} fontSize={'small'}/>
                            4 min
                        </Button>
                    </ButtonGroup>
                    : <Button
                        onClick={clearFreezeTime}
                        variant={'outlined'}
                        color={'error'}
                        size={'small'}
                        sx={{fontSize: 8, ml: 1, p: .1}}
                    >
                        Clear Timer
                    </Button>
            )}
        />

    }
}


export const FreezeInfoLayout: FC<{
    title: string,
    description: string,
    color: string,
    icon: ReactNode,
    timer?: ReactNode
    action?: ReactNode
}> = ({title, description, color, icon, timer, action}) => {
    return (
        <Stack direction={'row'} alignItems={'center'}>
            {icon}
            <Box>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography sx={{color: color}}>
                        {title}
                    </Typography>
                    {timer}
                    {action}
                </Stack>
                <Typography sx={{color: '#666', fontSize: 9}}>
                    {description}
                </Typography>
            </Box>
        </Stack>
    );
}
