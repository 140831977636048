import React, {FC, useCallback, useContext, useRef, useState} from 'react';
import {Box, Button, TextField, Typography, Link as MuiLink} from "@mui/material";
import {faker} from "@faker-js/faker";
import {PrincipalContext} from "../../PrincipalContext";
import GoogleIcon from '@mui/icons-material/Google';
import peng_clipboard from "../../rt_penguin_somb_clipboard.svg";

export const AddParticipantDialogGate: FC = () => {
    const [showAnonLogin, setShowAnon] = useState(false)
    const useAnonLogin = useCallback(() => setShowAnon(true), [setShowAnon])
    const useGoogleLogin = useCallback(() => setShowAnon(false), [setShowAnon])

    return (
        <Box sx={{p: 1}}>
            <Box sx={{textAlign: 'center'}}>
                <Typography onClick={useGoogleLogin} variant={'h6'} sx={{mt: 1, fontSize: 16, px: 4}}>
                    Wait... Who are you?
                </Typography>
                <Box sx={{height: 200, p: 1}}>
                    {/*<img src={logo} alt="logo" height={"100%"}/>*/}
                    <img src={peng_clipboard} alt="peng_clipboard" height={"100%"}/>
                </Box>
                {showAnonLogin
                    ? <LoginAnon useGoogleLogin={useGoogleLogin}/>
                    : <LoginGoogle useAnonLogin={useAnonLogin}/>
                }
            </Box>
        </Box>
    );
}

export const LoginGoogle: FC<{ useAnonLogin: () => void }> = ({useAnonLogin}) => {
    const {openLoginGoogle} = useContext(PrincipalContext)
    return (
        <Box>
            <Button
                fullWidth
                variant={'contained'}
                sx={{mt: 2, flexGrow: 1}}
                onClick={openLoginGoogle}
                startIcon={<GoogleIcon/>}
            >
                Continue With Google
            </Button>
            <Box sx={{textAlign: 'center', my: .7}}>
                <MuiLink onClick={useAnonLogin} sx={{fontSize: 10, cursor: 'pointer'}}>Login as Guest</MuiLink>
            </Box>
        </Box>
    );
}

export const LoginAnon: FC<{ useGoogleLogin: () => void }> = ({useGoogleLogin}) => {
    const [name, setName] = useState<string>()
    const {loginAnon} = useContext(PrincipalContext)

    const onSubmit = useCallback(() => {
        const trimmed = name?.trim()
        if (trimmed) {
            loginAnon(trimmed)
        }
    }, [name, loginAnon])

    const inputEl = useRef<HTMLDivElement>(null);
    const onButtonClick = () => {
        // `current` points to the mounted text input element
        setName(faker.name.firstName())
        // inputEl.current?.focus();
    };

    return (
        <Box>
            <Box>
                <TextField
                    fullWidth
                    focused
                    autoFocus
                    ref={inputEl}
                    value={name}
                    sx={{mt: 2}}
                    label={"Name"}
                    helperText={"Enter your name to begin scoring options"}
                    onChange={e => setName(e.target.value)}
                />
                {localStorage.getItem("DEV_MODE") && <Button onClick={onButtonClick}>use fake name</Button>}
                <Button
                    fullWidth
                    variant={'contained'}
                    sx={{mt: 2}}
                    onClick={onSubmit}

                >
                    Continue As Guest
                </Button>
                <Box sx={{textAlign: 'center', my: .7}}>
                    <MuiLink onClick={useGoogleLogin} sx={{fontSize: 10, cursor: 'pointer'}}>Login with Google</MuiLink>
                </Box>
            </Box>

        </Box>
    );
}
