import React, {FC, useCallback, useContext, useMemo} from 'react';
import {
    Badge,
    Box,
    Card,
    CircularProgress,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import ForwardIcon from '@mui/icons-material/Forward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import {BoardManagerContext} from "../index";
import {PointCard} from "../../models";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
// import HeatIcon from '@mui/icons-material/WbSunny';

export const PointableCard: FC<{
    item: PointCard,
    addPointOverride?: () => void
}> = ({item, addPointOverride}) => {
    const {maxPoints, cardPoints, deleteCard, principal, isFrozen} = useContext(BoardManagerContext)
    const {
        addPoint,
        removePoint,
        points,
        pointsAvailable
    } = principal

    const onAdd = useCallback(() => addPoint(item.id), [item.id, addPoint])
    const onRemove = useCallback(() => removePoint(item.id), [item.id, removePoint])

    const pointCount = useMemo(() => cardPoints[item.id].length, [cardPoints, item.id])
    const principalPointCount = useMemo(() => points[item.id] || 0, [points, item.id])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onDelete = useCallback(() => {
        deleteCard(item.id)
        handleClose()
    }, [item.id, deleteCard])

    const turnBlue = useMemo(() => isFrozen && pointsAvailable === 0, [isFrozen, pointsAvailable])

    const {palette} = useTheme()
    return (
        <Card sx={{m: 1, position: 'relative'}} elevation={3}>
            <Stack direction={'row'} sx={{p: 1}} alignItems={'center'}>
                <Box sx={{maxWidth: 24, flexShrink: 1, position: 'relative'}}>
                    <IconButton onClick={addPointOverride || onAdd} aria-label="add point" size="small" sx={{p: 0}}
                                disabled={!addPointOverride && pointsAvailable === 0}>
                        <ForwardIcon sx={{transform: 'rotate(270deg)', height: 24, width: 24}}/>
                    </IconButton>
                    <IconButton onClick={onRemove} aria-label="remove point"
                                disabled={isFrozen || principalPointCount <= 0}
                                size="small" sx={{p: 0}}>
                        {/*{isFrozen && false*/}
                        {/*    ? <AcUnitOutlinedIcon sx={{color: '#CCC', height: 16, width: 16, padding: '0 4px'}}/>*/}
                        {/*    : */}
                        {/*}*/}
                            <ForwardIcon sx={{transform: 'rotate(90deg)', height: 24, width: 24}}/>
                    </IconButton>
                    {/*{showSnowflake && <SnowflakeFadeOverlay/>}*/}
                </Box>
                <Box sx={{position: 'relative', maxWidth: 120}}>
                    <Typography variant={'h6'} sx={{
                        width: 46,
                        top: 10,
                        textAlign: 'center',
                        position: 'absolute',
                        pointerEvents: 'none',
                        userSelect: 'none',
                        fontSize: 17,
                        fontWeight: 800,
                    }}>
                        {pointCount}
                    </Typography>
                    <CircularProgress
                        size={46}
                        variant={'determinate'}
                        value={100} color={'inherit'}
                        sx={{color: '#f8e4cd', position: 'absolute'}}
                        // sx={{color: '#CCC', position: 'absolute'}}
                    />
                    <Badge
                        badgeContent={principalPointCount}
                        color={turnBlue ? "primary" : "warning"}
                        overlap={'circular'}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{
                            userSelect: 'none'
                        }}
                    >
                        <CircularProgress
                            size={46}
                            color={turnBlue ? "primary" : "warning"}
                            variant={'determinate'}
                            value={(pointCount / maxPoints) * 100}
                            // sx={{color: turnBlue ? cyan['A400'] : '#F29D14'}}
                        />
                    </Badge>
                    {/*<CircularProgress*/}
                    {/*    size={80}*/}
                    {/*    variant={'determinate'}*/}
                    {/*    value={(item.pointCount / totalPointsOnBoard) * 100}*/}
                    {/*    sx={{color: '#ff8102'}}*/}
                    {/*/>*/}
                </Box>
                <Box sx={{
                    width: '100%',
                    overflow: 'hidden'
                }}>
                    <Typography sx={{mx: 2, fontSize: 12}} variant={'body2'}>
                        {item.description}
                    </Typography>
                </Box>
            </Stack>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{position: 'absolute', top: 0, right: 0}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'center', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small"/>
                    </ListItemIcon>
                    Delete Card
                </MenuItem>
            </Menu>
        </Card>
    );
}

export const PointableCardDisabled: FC<{
    item: PointCard,
    deleteCard: (id: string) => void
}> = ({item, deleteCard}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onDelete = useCallback(() => {
        deleteCard(item.id)
        handleClose()
    }, [item.id, deleteCard])


    return (
        <Card sx={{m: 1, position: 'relative'}} elevation={3}>
            <Stack direction={'row'} sx={{p: 1}} alignItems={'center'}>
                <Box sx={{maxWidth: 35, flexShrink: 1}}>
                    <IconButton aria-label="add point" size="small" disabled={true}>
                        <ForwardIcon sx={{transform: 'rotate(270deg)'}}/>
                    </IconButton>
                    <IconButton aria-label="remove point" disabled={true} size="small">
                        <ForwardIcon sx={{transform: 'rotate(90deg)'}}/>
                    </IconButton>
                </Box>
                <Box sx={{position: 'relative', maxWidth: 120}}>
                    <Typography variant={'h6'} sx={{
                        width: 60,
                        top: 15,
                        textAlign: 'center',
                        position: 'absolute',
                        pointerEvents: 'none',
                        userSelect: 'none'
                    }}>
                        0
                    </Typography>
                    <CircularProgress
                        size={60}
                        variant={'determinate'}
                        value={100} color={'inherit'}
                        sx={{color: '#f8e4cd', position: 'absolute'}}
                        // sx={{color: '#CCC', position: 'absolute'}}
                    />
                    <Badge
                        badgeContent={0}
                        color="warning"
                        overlap={'circular'}
                        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        sx={{userSelect: 'none'}}
                    >
                        <CircularProgress
                            size={60}
                            variant={'determinate'}
                            value={0}
                            sx={{color: '#F29D14'}}
                        />
                    </Badge>
                    {/*<CircularProgress*/}
                    {/*    size={80}*/}
                    {/*    variant={'determinate'}*/}
                    {/*    value={(item.pointCount / totalPointsOnBoard) * 100}*/}
                    {/*    sx={{color: '#ff8102'}}*/}
                    {/*/>*/}
                </Box>
                <Box sx={{
                    width: '100%',
                    overflow: 'hidden'
                }}>
                    <Typography sx={{mx: 2, fontSize: 12}} variant={'body2'}>
                        {item.description}
                    </Typography>
                </Box>
            </Stack>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{position: 'absolute', top: 0, right: 0}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'center', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small"/>
                    </ListItemIcon>
                    Delete Card
                </MenuItem>
            </Menu>
        </Card>
    );
}


const SnowflakeFadeOverlay = () => {
    const {palette} = useTheme()
    return (
        <Box sx={{
            position: 'absolute',
            top: 16,
            textAlign: 'center',
            width: '100%',
        }}>
            <Box sx={{
                background: '#FFF',
                borderRadius: 7,
                height: 16,
                width: 16,
                margin: 'auto',
                boxShadow: '0px 0px 6px 2px #FFF'
            }}>
                <AcUnitOutlinedIcon sx={{color: palette.secondary.light, height: 16, width: 16}}/>
            </Box>
        </Box>
    )
}
