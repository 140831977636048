import React, {FC, useCallback, useContext, useState} from 'react';
import {Box, Button, Container, TextField} from "@mui/material";
import {BoardManagerContext} from "../index";

export const EditBoardDialog: FC<{ onClose: () => void }> = ({onClose}) => {
    const {meta, updateMeta} = useContext(BoardManagerContext)
    const [title, setTitle] = useState<string>(meta!.title)
    const [scoringInstructions, setScoringInstructions] = useState<string>(meta!.scoringInstructions)

    const onSubmit = useCallback(() => {
        const titleTrim = title?.trim()
        const scoringInstructionsTrim = scoringInstructions?.trim()
        if (titleTrim && scoringInstructionsTrim && (titleTrim !== meta?.title || scoringInstructionsTrim !== meta?.scoringInstructions)) {
            updateMeta({
                title: titleTrim,
                scoringInstructions: scoringInstructionsTrim
            })
        }
        onClose()
    }, [title, scoringInstructions, updateMeta, meta?.scoringInstructions, meta?.title, onClose])

    return (
        <Container maxWidth={'sm'}>
            <Box>
                <TextField
                    fullWidth
                    autoFocus
                    value={title}
                    sx={{mt: 3}}
                    label={"Title"}
                    onChange={e => setTitle(e.target.value)}
                />
                <TextField
                    fullWidth
                    value={scoringInstructions}
                    sx={{mt: 2}}
                    label={"Scoring Instructions"}
                    multiline
                    rows={4}
                    onChange={e => setScoringInstructions(e.target.value)}
                />
            </Box>
            <Box>
                <Button
                    fullWidth
                    variant={'contained'}
                    sx={{mt: 2, mb: 3}}
                    onClick={onSubmit}
                >
                    Save
                </Button>
            </Box>
        </Container>
    );
}
