import React, {FC, useState} from 'react';
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip} from "@mui/material";
import CopyLinkIcon from '@mui/icons-material/ContentPaste';
import LinkCopiedIcon from '@mui/icons-material/AssignmentTurnedIn';


export const ShareUrlBox: FC<{ shareUrl: string }> = ({shareUrl}) => {
    const [linkCopied, setLinkCopied] = useState(false)

    const handleClickCopyLink = () => {
        setLinkCopied(true)
        navigator.clipboard.writeText(shareUrl);
    };

    const handleMouseDownCopyLink = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <FormControl sx={{m: 0}} variant="outlined" fullWidth>
            <InputLabel htmlFor="copy-link-input">Share URL</InputLabel>
            <OutlinedInput
                id="copy-link-input"
                fullWidth
                label={'Share URL'}
                value={shareUrl}
                endAdornment={
                    <InputAdornment position="end">
                        <Tooltip title={linkCopied ? "Link Copied" : "Copy Share Link"}>
                            <IconButton
                                aria-label="copy link to clipboard"
                                onClick={handleClickCopyLink}
                                onMouseDown={handleMouseDownCopyLink}
                                edge="end"
                            >
                                {linkCopied
                                    ? <LinkCopiedIcon color={'primary'}/>
                                    : <CopyLinkIcon/>
                                }
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
}
