import React, {FC} from 'react';
import {Box, Typography} from "@mui/material";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";

export const MaxPointsSelector: FC<{ value: number, update: (newMax: number) => void }> = ({value, update}) => {
    const handleVoteCountChange = (
        event: React.MouseEvent<HTMLElement>,
        newVoteCount: number,
    ) => {
        if (newVoteCount) {
            update(newVoteCount);
        }
    };

    return (
        <Box sx={{mt: 2}}>
            <Typography sx={{color: '#666', fontSize: 12, mb: 1}}>Points per participant</Typography>
            <ToggleButtonGroup
                fullWidth
                value={value}
                exclusive
                onChange={handleVoteCountChange}
                aria-label="Vote Count"
                color={'primary'}
            >
                <ToggleButton value={3} aria-label="3">3</ToggleButton>
                <ToggleButton value={5} aria-label="5">5</ToggleButton>
                <ToggleButton value={9} aria-label="9">9</ToggleButton>
            </ToggleButtonGroup>
        </Box>

    );
}
