import React, {FC, useCallback, useContext, useMemo, useState} from 'react';
import {Box, Button, Dialog, Stack, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {ShareUrlBox} from "../../comp/ShareUrlBox";
import {EditBoardDialog} from "../../comp/EditBoardDialog";
import {ParticipantStatusView} from "../../sections/ParticipantStatusView";
import {BoardManagerContext} from "../../index";

export const InfoSectionScoring: FC<{}> = ({}) => {
    const {resetParticipants, meta: metaOpt} = useContext(BoardManagerContext)
    const meta = metaOpt!
    const shareUrl = useMemo(() => `https://roomtemp.io/b/${meta.id}`, [meta.id])

    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const openEditBoardDialog = useCallback(() => setEditDialogOpen(true), [setEditDialogOpen])
    const closeEditBoardDialog = useCallback(() => setEditDialogOpen(false), [setEditDialogOpen])

    return (
        <Box>
            <Box sx={{mb: 5}}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'h6'}>
                        How to Score
                    </Typography>
                    <Button
                        onClick={openEditBoardDialog}
                        sx={{fontSize: 10, color: '#666'}}
                        size={'small'}
                        endIcon={<EditIcon/>}
                    >
                        Edit
                    </Button>
                </Stack>
                <Dialog onClose={closeEditBoardDialog} open={editDialogOpen}>
                    <EditBoardDialog onClose={closeEditBoardDialog}/>
                </Dialog>
                <Typography variant={'body2'}>
                    {meta.scoringInstructions}
                </Typography>
            </Box>
            <Box sx={{my: 5}}>
                <ShareUrlBox shareUrl={shareUrl}/>
            </Box>
            <Box sx={{my: 5}}>
                <ParticipantStatusView/>
                <Button onClick={resetParticipants} sx={{mt: 2}} fullWidth variant={'contained'}>
                    Reset Participant Votes
                </Button>
            </Box>
        </Box>
    );
}
