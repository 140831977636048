import React, {FC, useContext} from 'react';
import {Avatar, Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography} from "@mui/material";
import {PrincipalContext} from "../../../PrincipalContext";
import {Logout} from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const UserAvatar: FC = () => {
    const {principal, logout, openLoginDialog} = useContext(PrincipalContext)
    const name = principal?.name || 'Guest'

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{mr: 4}}>
            {!!principal
                ? <Tooltip title="Account settings">
                    <Stack direction={'row'} alignItems={'center'} spacing={1} onClick={handleClick}>
                        <Typography sx={{fontSize: 10, fontWeight: 700}}>
                            {name.toUpperCase()}
                        </Typography>
                        <IconButton

                            size="small"
                            sx={{ml: 2}}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{height: 36, width: 36}}/>
                        </IconButton>
                    </Stack>
                </Tooltip>
                : <Button startIcon={<AccountCircleIcon/>} onClick={openLoginDialog}>Login</Button>
            }

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: 200,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    )
        ;
}
