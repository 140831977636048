import React, {FC, useContext, useMemo} from 'react';
import {Box, Chip, Grid, Stack, Typography} from "@mui/material";
import {BoardManagerContext} from "../index";
import {Participant} from "../../models";
import {orderBy} from "lodash";
import {grey} from "@mui/material/colors";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

export const ParticipantStatusView: FC = () => {
    const {participants, meta} = useContext(BoardManagerContext)
    const ordered = useMemo(() => orderBy(participants, p => p.name), [participants])
    const waitingOnParticipants = useMemo(
        () => participants.filter(p => p.points.length < meta?.maxPointsPerParticipant!).length,
        [participants, meta?.maxPointsPerParticipant]
    )
    return (
        <Box>
            <Typography variant={'h6'}>
                Participants
            </Typography>
            {waitingOnParticipants === 0
                ? <Typography variant={'body2'} sx={{color: grey[600], fontWeight: 700}}>
                    All participants have completed scoring.
                </Typography>
                : waitingOnParticipants === 1
                    ? <Typography variant={'body2'} sx={{color: grey[500], fontWeight: 700}}>
                        1 participant has not completed scoring.
                    </Typography>
                    : <Typography variant={'body2'} sx={{color: grey[500], fontWeight: 700}}>
                        {waitingOnParticipants} participants have not completed scoring.
                    </Typography>
            }


            <Stack spacing={0} direction={'row'} sx={{mt: 1, flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                {ordered.map(p => (
                    <ParticipantStatusViewChip key={p.id} participant={p} maxPoints={meta?.maxPointsPerParticipant!}/>
                ))}
            </Stack>
        </Box>
    );
}

export const ParticipantStatusViewChip: FC<{
    participant: Participant,
    maxPoints: number
}> = ({participant, maxPoints}) => {
    const allPointsAssigned = useMemo(
        () => participant.points.length === maxPoints,
        [participant.points.length, maxPoints]
    )
    return (
        <Chip
            sx={{my: .5, mr: .5, fontWeight: 700, color: allPointsAssigned ? '#999' : '#444'}}
            color={'default'}
            size={'small'}
            icon={allPointsAssigned
                ? <CheckCircleIcon sx={{color: '#999 !important'}}/>
                : <HourglassBottomIcon/>}
            label={participant.name}
        />
    );
}
